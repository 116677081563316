import { useEffect, useState } from 'react';
import Map from './components/Map';
import { Layers, TileLayer, VectorLayer } from './components/Layers';
import { Style, Stroke } from 'ol/style';
import Feature from 'ol/Feature';
import Polyline from 'ol/format/Polyline';
import Point from 'ol/geom/Point';
import { osm, vector } from './components/Source';
//import { Controls, FullScreenControl } from './components/Controls';
import { addLocationMarkers, getVanData } from './Utils';
import mapConfig from './config.json';
import './App.css';
import { live, logoCombis } from './assets/images';
import VectorSource from 'ol/source/Vector';
import {
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiBasicTable,
  EuiProvider,
  EuiTitle,
  EuiHeader,
  EuiHeaderSectionItem,
  EuiImage,
  EuiHeaderLinks,
  EuiHeaderLink,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageTemplate,
  EuiToast,
} from '@elastic/eui';
import { EuiButton } from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_dark.css';
import { boundingExtent } from 'ol/extent';
import { createVectorLayers } from './Utils/createVectorLayers';
import { createFeatureRoutes } from './Utils/createFeatureRoutes';
import { styles } from './Utils/createStyles';

const finalMarkers = [
  mapConfig.udesaLonLat,
  mapConfig.estacionLonLat,
  mapConfig.riberaLonLat,
  mapConfig.jacarandaLonLat,
];

const App = () => {
  const [screenSize, setScreenSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [center, setCenter] = useState(mapConfig.center);
  // const [oldVanLoc, setOldVanLoc] = useState<Feature<Point>>();
  const [nextVanTime, setNextVanTime] = useState({ horario: '', loc: '' });
  const [isLeavingNow, setIsLeavingNow] = useState(false);
  const [isNotfirst, setIsNotfirst] = useState(false);
  const [isTooEarly, setIsTooEarly] = useState(false);
  const [isContiniousEarly, setIsContiniousEarly] = useState(false);
  const [isContiniousLate, setIsContiniousLate] = useState(false);
  const [zoom, setZoom] = useState(15);
  //const [data, setData] = useState<any>();
  const [features, setFeatures] = useState<any[]>();
  //const [durationCampus, setDurationCampus] = useState<any>();
  //const [durationEstacion, setDurationEstacion] = useState<any>();
  const [featuresLoc, setFeaturesLoc] = useState(
    addLocationMarkers([
      [mapConfig.udesaLonLat, 'Campus Victoria (Vito Dumas)'],
      [mapConfig.estacionLonLat, 'Estacion Victoria (Mitre)'],
      [mapConfig.riberaLonLat, 'Campus Victoria (Ribera)'],
      [mapConfig.jacarandaLonLat, `${'Residencia Jacaranda (Necochea 810)'}`],
    ])
  );
  const [isTimeModalVisible, setIsTimeModalVisible] = useState(false);
  const [isInformationModalVisible, setIsInformationModalVisible] =
    useState(false);
  const [isInformationTIModalVisible, setIsInformationTIModalVisible] =
    useState(false);
  const [featuresLocVan, setFeaturesLocVan] = useState<Feature<Point>[]>();
  //const [bear, setBear] = useState();

  //bear && !featuresLocVan && getVanData(setFeaturesLocVan, bear);

  const closeTimeModal = () => setIsTimeModalVisible(false);
  const showTimeModal = () => setIsTimeModalVisible(true);

  const closeInformationModal = () => setIsInformationModalVisible(false);
  const showInformationModal = () => setIsInformationModalVisible(true);

  const closeInformationTIModal = () => setIsInformationTIModalVisible(false);
  const showInformationTIModal = () => setIsInformationTIModalVisible(true);

  let modalTime;
  let modalInformation;
  let modalInformationTI;

  const dayOfWeek = new Date().getDay();
  const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
  let horarios = mapConfig.horarios;

  if (isTimeModalVisible) {
    modalTime = (
      <EuiModal onClose={closeTimeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h2>Horarios</h2>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <h1 style={{ fontSize: 'larger' }}>
            De 8:20 a 9:20 y de 16:50 a 17:40, las combis realizan viajes
            continuos entre la estación Victoria y el Campus en ambos sentidos.
            Fuera de esos rangos, los horarios son:{' '}
          </h1>
          <br />
          <EuiBasicTable
            items={horarios}
            rowHeader="horario"
            isSelectable={true}
            rowProps={{ onClick: () => {} }}
            columns={[
              {
                field: 'horario',
                name: 'Horario',
                sortable: true,
                truncateText: false,
                width: '80%',
              },
              {
                field: 'loc',
                name: 'Salida desde',
                sortable: true,
                truncateText: false,
                width: '100%',
              },
            ]}
          />
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton size="s" onClick={closeTimeModal}>
            Close
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  if (isInformationModalVisible) {
    modalInformation = (
      <EuiModal onClose={closeInformationModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h2>Información</h2>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiToast
            title="Te acercamos al Campus desde la Estación de Victoria. ¡Servicio gratuito!"
            color="success"
            iconType=""
            css={{ 'font-size': '60px' }}
          >
            <p>
              La combi une al Campus con la estación Victoria de la línea Mitre,
              y hace paradas intermedias en las avenidas Del Libertador y Perón.
            </p>

            <p>
              El servicio es gratuito y está disponible de lunes a viernes de
              8:20 a 20:40 horas.
            </p>

            <p>
              Hasta las 16 horas, las entradas y salidas son por Camino de la
              Ribera. A partir de las 16 horas, las entradas y salidas son por
              Dormis.
            </p>

            <p>
              Contamos con otra unidad para brindar el servicio a los alumnos
              que residan en Jacarandá. Pueden consultar los horarios en la
              recepción de la Residencia Jacaranda.
            </p>
          </EuiToast>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton size="s" onClick={closeInformationModal}>
            Close
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  if (isInformationTIModalVisible) {
    modalInformation = (
      <EuiModal onClose={closeInformationTIModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h2>Detectaste algun problema con el servicio?</h2>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiToast
            title=""
            color="success"
            iconType=""
            css={{ 'font-size': '60px' }}
          >
            <p>
              Por favor si detectaste algun inconveniente con la web o el
              servicio, por favor enviar un email a ticketsti@udesa.edu.ar
            </p>
          </EuiToast>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton size="s" onClick={closeInformationTIModal}>
            Close
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  //addVanMarker(finalMarkersVan);
  /*   console.log(
    featuresLocVan?.getGeometry()?.getCoordinates(),
  ); */
  /* getRouteTime(
    [featuresLocVan?.getGeometry()?.getCoordinates(), udesaLonLat[0]],
    setDuration
  ); */

  /* const routingEstacion = new Polyline({
    factor: 1e6,
  }).readGeometry(polylineEstacion.routes[0].geometry);
  const featureRouteEstacion = new Feature({
    type: 'route',
    geometry: routingEstacion,
    zIndex: 99,
    name: 'Ruta Entrada Campus por Vito Dumas',
  });
  featureRouteEstacion.setStyle(styles.route1);

  const routingRibera = new Polyline({
    factor: 1e6,
  }).readGeometry(polylineRibera.routes[0].geometry);
  const featureRouteRibera = new Feature({
    type: 'route',
    geometry: routingRibera,
    zIndex: 99,
    name: 'Ruta Entrada Campus por Ribera',
  });
  featureRouteRibera.setStyle(styles.route2);

  const routingJacaranda = new Polyline({
    factor: 1e6,
  }).readGeometry(polylineJacaranda.routes[0].geometry);
  const featureRouteJacaranda = new Feature({
    type: 'route',
    geometry: routingJacaranda,
    zIndex: 99,
    name: 'Ruta Residencia Jacaranda',
  });
  featureRouteJacaranda.setStyle(styles.route3); */

  const boundsExt = boundingExtent(finalMarkers);

  const vanInformation = async () => {
    await getVanData(
      setFeaturesLocVan,
      featuresLocVan,
      setIsNotfirst,
      isNotfirst
    );
    if (featuresLocVan) {
      // setOldVanLoc(featuresLocVan[0]);

      let vanData = featuresLocVan[1]?.getGeometry()?.getCoordinates();
      const coordsVans: any[] = [];
      featuresLocVan.forEach(
        (f) => f && coordsVans.push(f?.getGeometry()?.getCoordinates())
      );

      if (coordsVans) {
        boundingExtent(coordsVans);
      }
      if (
        featuresLocVan[0]?.getGeometry()?.getCoordinates()[0] === 0 ||
        featuresLocVan[0]?.getGeometry()?.getCoordinates() === undefined
      ) {
        vanData = undefined;
      }

      // This lines establish a time duration to destination
      /* if (vanData !== undefined) {
        getRouteTime(
          [featuresLocVan[0]?.getGeometry()?.getCoordinates(), udesaLonLat[0]],
          setDurationCampus
        );
        // the next two lines sets the center and zoom to the van
        setCenter(vanData);
        setZoom(zoom);
      } */
    }
  };

  useEffect(() => {
    function debounce(func: (args_0: any) => void) {
      let timer: any;
      return function (event: any) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(func, 100, event);
      };
    }
    function handleResize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener(
      'resize',
      debounce(function (e) {
        handleResize();
      })
    );
  });

  const featureRoutes = createFeatureRoutes();

  useEffect(() => {
    /*  setCenter(
      boundingExtent([
        mapConfig.udesaLonLat,
        mapConfig.riberaLonLat,
        mapConfig.estacionLonLat,
      ])
    ); */
    //console.log(featuresLocVan?.getGeometry()?.getCoordinates(), 'datos');
    //setZoom(zoom);
    //console.log(featuresLocVan?.getGeometry()?.getCoordinates());
    // !bear && getBearer(setBear);
    const h = new Date().getHours();
    const m = new Date().getMinutes();
    const horaCerca = h.toString() + ':' + m.toString();
    const previousTime = new Date();

    const pt = new Date(previousTime.getTime());
    pt.setHours(0);
    pt.setMinutes(0);
    pt.setSeconds(0);
    setNextVanTime({ horario: '', loc: '' });
    setIsLeavingNow(false);
    setIsTooEarly(false);

    if (h === 16 && m > 30 && m < 50) {
      setIsContiniousLate(false);
      setIsLeavingNow(false);
      setNextVanTime({ horario: '16:50', loc: 'cont' });
    } else if (h === 8 && m >= 20) {
      setIsContiniousEarly(true);
      setIsLeavingNow(true);
      setNextVanTime({ horario: 'cont', loc: 'cont' });
    } else if (h === 9 && m <= 20) {
      setIsContiniousEarly(true);
      setIsLeavingNow(true);
      setNextVanTime({ horario: 'cont', loc: 'cont' });
    } else if (h === 16 && m >= 50) {
      setIsContiniousLate(true);
      setIsLeavingNow(true);
      setNextVanTime({ horario: 'cont', loc: 'cont' });
    } else if (h === 17 && m <= 40) {
      setIsContiniousLate(true);
      setIsLeavingNow(true);
      setNextVanTime({ horario: 'cont', loc: 'cont' });
    } else {
      horarios.forEach((dh) => {
        if (dh.state === '') {
          const currentDate = new Date();

          const now = new Date(currentDate.getTime());
          now.setHours(parseInt(horaCerca.split(':')[0]));
          now.setMinutes(parseInt(horaCerca.split(':')[1]));
          now.setSeconds(0);

          const testDate = new Date(currentDate.getTime());
          testDate.setHours(parseInt(dh.horario.split(':')[0]));
          testDate.setMinutes(parseInt(dh.horario.split(':')[1]));
          testDate.setSeconds(0);

          if (now <= testDate && !(pt > now)) {
            setNextVanTime(dh);
            if (now.getTime() === testDate.getTime()) {
              setIsLeavingNow(true);
            } else if (!isLeavingNow) {
              pt.setHours(parseInt(dh.horario.split(':')[0]));
              pt.setMinutes(parseInt(dh.horario.split(':')[1]));
            }
          } else if (now.getTime() === testDate.getTime()) {
            //setNextVanTime(dh);
            setIsLeavingNow(true);
            //pt.setHours(parseInt(dh.horario.split(':')[0]));
            //pt.setMinutes(parseInt(dh.horario.split(':')[1]));
          } else if (now > testDate) {
            setIsTooEarly(true);
            /* console.log(pt.getMinutes(), '+++++', pt.getHours(), '+++++');
          setNextVanTime({ horario: '', loc: '' });
          setIsLeavingNow(false); */
          }
        }
      });
    }

    /* if (featuresLocVan) {
      console.log(featuresLocVan);
      setOldVanLoc(featuresLocVan[0]);

      let vanData = featuresLocVan[0]?.getGeometry()?.getCoordinates();
      if (
        featuresLocVan[0]?.getGeometry()?.getCoordinates()[0] === 0 ||
        featuresLocVan[0]?.getGeometry()?.getCoordinates() === undefined
      ) {
        vanData = undefined;
      }

      if (
        vanData !== undefined &&
        oldVanLoc?.getGeometry()?.getCoordinates()[0] !==
          featuresLocVan[0]?.getGeometry()?.getCoordinates()[0] &&
        oldVanLoc?.getGeometry()?.getCoordinates()[1] !==
          featuresLocVan[0]?.getGeometry()?.getCoordinates()[1]
      ) {
        getRouteTime(
          [featuresLocVan[0]?.getGeometry()?.getCoordinates(), udesaLonLat[0]],
          setDurationCampus
        );
        setCenter(vanData);
      }
    } */
    vanInformation();
    /* setTimeout(function () {
      if (-1 === -1) {
        vanInformation();
      }
    }, 2000); */
    //setCenter(center);
  }, [featuresLocVan, setFeaturesLocVan, featuresLoc]);

  return (
    <>
      <EuiPageTemplate
        restrictWidth={false}
        grow={true}
        paddingSize="none"
        style={{ height: '100vh' }}
      >
        <EuiProvider colorMode="dark">
          <EuiHeader
            theme="dark"
            position={'static'}
            sections={[
              {
                items: [
                  <EuiImage size={50} alt="logo" src={logoCombis} />,
                  <EuiTitle
                    css={{
                      'font-weight': 'inherit!important',
                      'overflow-wrap': 'break-word!important',
                      'word-break': 'break-word',
                      'font-size': '2rem',
                      /* 'font-size': '1.9286rem', */
                      'line-height': '2.2857rem',
                      'margin-left': '10px',
                      paddingBottom: '5px',
                      color: '#DFE5EF',
                    }}
                  >
                    <h1> UdeSA Combis</h1>
                  </EuiTitle>,
                ],
              },
              {
                items: [
                  <EuiHeaderLinks>
                    <EuiButton onClick={showTimeModal}>Horarios</EuiButton>
                    <EuiButton
                      size={'s'}
                      fill
                      iconType="iInCircle"
                      minWidth={30}
                      style={{
                        width: '30px',
                        height: '40px',
                        alignSelf: 'flex-end',
                      }}
                      onClick={showInformationTIModal}
                    ></EuiButton>
                    {/* <EuiHeaderLink>Ir a UdeSA</EuiHeaderLink> */}
                  </EuiHeaderLinks>,
                ],
              },
            ]}
          ></EuiHeader>
          <EuiPageTemplate.Section grow>
            {featuresLoc && (
              <Map
                center={center}
                zoom={zoom}
                extent={boundsExt}
                screen={screenSize}
              >
                <Layers>
                  <TileLayer
                    source={
                      osm() /* new XYZ({
                        url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
                      }) */
                    }
                    zIndex={0}
                  />
                  {features && (
                    <VectorLayer
                      source={vector({ features })}
                      style={undefined}
                    />
                  )}
                  <VectorLayer
                    source={vector({ features: featuresLoc })}
                    style={undefined}
                    zIndex={999999}
                  />
                  <VectorLayer
                    source={new VectorSource({ features: [featureRoutes[0]] })}
                    style={styles.route1}
                  />
                  <VectorLayer
                    source={new VectorSource({ features: [featureRoutes[1]] })}
                    style={styles.route2}
                  />
                  <VectorLayer
                    source={new VectorSource({ features: [featureRoutes[2]] })}
                    style={styles.route3}
                  />
                  <VectorLayer
                    source={new VectorSource({ features: [featureRoutes[3]] })}
                    style={styles.route3}
                  />
                  <VectorLayer
                    source={new VectorSource({ features: [featureRoutes[4]] })}
                    style={styles.route2}
                  />
                  <VectorLayer
                    source={new VectorSource({ features: [featureRoutes[5]] })}
                    style={styles.route1}
                  />

                  {featuresLocVan && (
                    <VectorLayer
                      source={vector({ features: featuresLocVan })}
                      style={undefined}
                      zIndex={9999999}
                    />
                  )}
                </Layers>
                {/* <Controls>
              <FullScreenControl />
            </Controls> */}
              </Map>
            )}
          </EuiPageTemplate.Section>
          <EuiPageTemplate.BottomBar
            paddingSize="s"
            position="sticky"
            // bottom={0}
          >
            <EuiFlexGroup justifyContent="spaceBetween" wrap>
              <EuiFlexItem grow={false}>
                {/* {durationCampus && nextVanTime.horario !== '' ? ( */}
                {/* <EuiTitle>
              {Math.round(durationCampus / 60) === 1 ? (
                <h1>
                  La combi esta a {Math.round(durationCampus / 60)} minuto para
                  llegar a {nextVanTime.loc}, y sale a las {nextVanTime.horario}
                </h1>
              ) : Math.round(durationCampus / 60) === 0 ? (
                <h1>
                  La combi esta llegando a {nextVanTime.loc}, y sale a las{' '}
                  {nextVanTime.horario}
                </h1>
              ) : (
                <h1>
                  La combi esta a {Math.round(durationCampus / 60)} minutos para
                  llegar a {nextVanTime.loc}, y sale a las {nextVanTime.horario}
                </h1>
              )}
            </EuiTitle> */}
                {nextVanTime.horario !== '' && !isWeekend ? (
                  !isLeavingNow ? (
                    <>
                      <EuiTitle
                        css={{
                          'font-weight': 'inherit!important',
                          'overflow-wrap': 'break-word!important',
                          'word-break': 'break-word',
                          'font-size': '1.9286rem',
                          'line-height': '2.2857rem',
                          color: '#DFE5EF',
                        }}
                      >
                        <h1
                          style={{ paddingLeft: '3rem', position: 'relative' }}
                        >
                          <img
                            src={live}
                            style={{
                              width: '70px',
                              left: '-20px',
                              top: '-20px',
                              position: 'absolute',
                            }}
                          />
                          La proxima combi sale a las {nextVanTime.horario}{' '}
                          {nextVanTime.loc !== 'cont' &&
                            'desde ' + nextVanTime.loc}
                          {/* desde {nextVanTime.loc} */}.
                        </h1>
                      </EuiTitle>
                    </>
                  ) : isContiniousEarly || isContiniousLate ? (
                    <>
                      <EuiTitle
                        css={{
                          'font-weight': 'inherit!important',
                          'overflow-wrap': 'break-word!important',
                          'word-break': 'break-word',
                          'font-size': '1.9286rem',
                          'line-height': '2.2857rem',
                          color: '#DFE5EF',
                        }}
                      >
                        <h1
                          style={{ paddingLeft: '3rem', position: 'relative' }}
                        >
                          <img
                            src={live}
                            style={{
                              width: '70px',
                              left: '-20px',
                              top: '-20px',
                              position: 'absolute',
                            }}
                          />
                          La combi en estos momentos tiene un servicio continuo
                          hasta las {isContiniousLate && '17:40'}
                          {isContiniousEarly && '9:20'} por favor revise el mapa
                          para conocer su arribo.
                        </h1>
                      </EuiTitle>
                    </>
                  ) : (
                    <>
                      <EuiTitle
                        css={{
                          'font-weight': 'inherit!important',
                          'overflow-wrap': 'break-word!important',
                          'word-break': 'break-word',
                          'font-size': '1.9286rem',
                          'line-height': '2.2857rem',
                          color: '#DFE5EF',
                        }}
                      >
                        <h1
                          style={{ paddingLeft: '3rem', position: 'relative' }}
                        >
                          <img
                            src={live}
                            style={{
                              width: '70px',
                              left: '-20px',
                              top: '-20px',
                              position: 'absolute',
                            }}
                          />
                          La combi esta saliendo ahora{' '}
                          {nextVanTime.loc !== 'cont' &&
                            'desde ' + nextVanTime.loc}
                          .
                        </h1>
                      </EuiTitle>
                    </>
                  )
                ) : !isWeekend ? (
                  <>
                    {/* <EuiTitle>
                <h1>
                  No hay combis al campus en los proximos minutos. Podes revisar
                  los horarios aca{' '}
                  <EuiButton
                    color={'primary'}
                    size={'s'}
                    fill
                    onClick={showModal}
                  >
                    Horarios
                  </EuiButton>
                </h1>
              </EuiTitle> */}
                    {dayOfWeek > 0 && dayOfWeek <= 5 ? (
                      isTooEarly ? (
                        dayOfWeek !== 5 ? (
                          <EuiTitle
                            css={{
                              'font-weight': 'inherit!important',
                              'overflow-wrap': 'break-word!important',
                              'word-break': 'break-word',
                              'font-size': '1.9286rem',
                              'line-height': '2.2857rem',
                              color: '#DFE5EF',
                            }}
                          >
                            <h1 style={{ gap: '1rem', display: 'flex' }}>
                              No hay combis en lo que resta del día. La proxima
                              sale mañana a las 8:20 desde la Estación Victoria.
                              Podes revisar los horarios acá{' '}
                              <EuiButton
                                color={'primary'}
                                size={'s'}
                                fill
                                onClick={showTimeModal}
                              >
                                Horarios
                              </EuiButton>
                            </h1>
                          </EuiTitle>
                        ) : (
                          <EuiTitle
                            css={{
                              'font-weight': 'inherit!important',
                              'overflow-wrap': 'break-word!important',
                              'word-break': 'break-word',
                              'font-size': '1.9286rem',
                              'line-height': '2.2857rem',
                              color: '#DFE5EF',
                            }}
                          >
                            <h1 style={{ gap: '1rem', display: 'flex' }}>
                              No hay combis al campus en lo que resta del día. Y
                              recorda que durante los fines de semana no
                              disponemos de este servicio. Podes revisar los
                              horarios acá{' '}
                              <EuiButton
                                color={'primary'}
                                size={'s'}
                                fill
                                onClick={showTimeModal}
                              >
                                Horarios
                              </EuiButton>
                            </h1>
                          </EuiTitle>
                        )
                      ) : (
                        <>
                          <EuiTitle
                            css={{
                              'font-weight': 'inherit!important',
                              'overflow-wrap': 'break-word!important',
                              'word-break': 'break-word',
                              'font-size': '1.9286rem',
                              'line-height': '2.2857rem',
                              color: '#DFE5EF',
                            }}
                          >
                            <h1 style={{ gap: '1rem', display: 'flex' }}>
                              La primer combi sale a las 8:20 desde la Estación
                              Victoria. Podes revisar los horarios acá{' '}
                              <EuiButton
                                color={'primary'}
                                size={'s'}
                                fill
                                onClick={showTimeModal}
                              >
                                Horarios
                              </EuiButton>
                            </h1>
                          </EuiTitle>
                        </>
                      )
                    ) : (
                      <EuiTitle
                        css={{
                          'font-weight': 'inherit!important',
                          'overflow-wrap': 'break-word!important',
                          'word-break': 'break-word',
                          'font-size': '1.9286rem',
                          'line-height': '2.2857rem',
                          color: '#DFE5EF',
                        }}
                      >
                        <h1 style={{ gap: '1rem', display: 'flex' }}>
                          No hay combis al campus en lo que resta del día. Podes
                          revisar los horarios acá{' '}
                          <EuiButton
                            color={'primary'}
                            size={'s'}
                            fill
                            onClick={showTimeModal}
                          >
                            Horarios
                          </EuiButton>
                        </h1>
                      </EuiTitle>
                    )}
                  </>
                ) : (
                  <>
                    <EuiTitle
                      css={{
                        'font-weight': 'inherit!important',
                        'overflow-wrap': 'break-word!important',
                        'word-break': 'break-word',
                        'font-size': '1.9286rem',
                        'line-height': '2.2857rem',
                        color: '#DFE5EF',
                      }}
                    >
                      <h1 style={{ gap: '1rem', display: 'flex' }}>
                        Durante los fines de semana no disponemos de este
                        servicio, pero te dejamos los horarios durante la semana{' '}
                        <EuiButton
                          color={'primary'}
                          size={'s'}
                          fill
                          onClick={showTimeModal}
                        >
                          Horarios
                        </EuiButton>
                      </h1>
                    </EuiTitle>
                  </>
                )}
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton
                  size={'s'}
                  fill
                  iconType="questionInCircle"
                  minWidth={30}
                  style={{ width: '30px', alignSelf: 'flex-end' }}
                  onClick={showInformationModal}
                ></EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPageTemplate.BottomBar>
          {modalTime}
          {modalInformation}
          {modalInformationTI}
        </EuiProvider>
      </EuiPageTemplate>
    </>
  );
};

export default App;
